
/* Custom styles for keypad layout */
.btn-group .btn {
  flex: 1; /* Ensure buttons take equal space */
  margin: 2px; /* Slight space between buttons */
}

/* Style the input to look similar to the provided image */
.form-control-lg {
  border-radius: 0.25rem;
}

/* Remove rounded corners from buttons in the first/last position to match the image */
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-radius: 0;
}

/* Additional padding adjustment may be needed depending on your exact design */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: lightgray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* LoadingScreen.css */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  padding-top: 24px;
  z-index: 1000; /* High z-index to ensure it's above other content */
}

.loading-logo {
  max-width: 300px; /* Set the max width to match the image in the app */
  margin-bottom: 120px; /* Set the margin bottom to match the image in the app */
  animation: loadLogo 3s ease-in-out forwards; /* Animation to 'load' the logo */
}

@keyframes loadLogo {
  0% {
    clip-path: inset(100% 0 0 0); /* Start with the full image clipped */
    transform: scale(0.5); /* Start with the logo smaller */
    margin-bottom: 120px; /* Start with the desired margin bottom */
  }
  50% {
    transform: scale(1.5); /* Enlarge the logo */
    margin-bottom: calc(120px + (1.5 * 20px)); /* Adjust the margin bottom as the logo grows */
  }
  100% {
    clip-path: inset(0 0 0 0); /* End with the full image visible */
    transform: scale(1); /* Return to normal scale */
    margin-bottom: 120px; /* Ensure the margin bottom is correct */
  }
}

@keyframes unfold {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px; /* This should be higher than the total height of your keypad */
    opacity: 1;
  }
}

.unfold-container {
  overflow: hidden; /* Hide the content as it 'unfolds' */
  animation: unfold 2s ease forwards; /* Adjust the timing as needed */
}

body {
  background-color: lightgray;
}

.bg-success {
  background-color: darkgray !important;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-out;
}

.white-background {
  background-color: white;
  display: inline-block;
  padding: .375rem .75rem; /* Similar padding as inputs for consistency */
  margin-bottom: .5rem; /* Space below the label */
  width: 100%; /* Full width to match inputs */
  border: 1px solid #ced4da; /* Similar border to inputs */
  border-radius: .25rem; /* Rounded corners like inputs */
}
